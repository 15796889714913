.contestCard__container {
  width: 31%;
  text-align: center;
  cursor: pointer;
  color: #2a2a2a7f;
  cursor: pointer;
  &:hover {
    color: var(--clr-primary-300);
  }
  @media (max-width: 600px) {
    // justify-content: center;
    width: 100%;
}

  .contestImg__holder {
    img {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  p {
    padding-top: 5px;
    padding-top: 1rem;
    font-size: 1.3rem;
    text-align: center;
    color: #212121;
  }
}
