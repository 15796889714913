.mycontests-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
  width: 100%;

  .backAndCancle-modal {
    text-align: center;

    @media (max-width: 600px) {
      padding: 0 1rem;
    }

  
  
    p:nth-child(1) {
      color: #2A2A2A;
      font-size: 18;
      width: fit-content;
      margin: 0 auto;
    }
    
    p:nth-child(2) {
      color: #2A2A2A7F;
      font-size: 14px;
      padding: 1rem 0;
      width: fit-content;
      margin: 0 auto;
    }

    .back--cancle-btn-container {
      display: flex;
      width: 100%;
      padding: 2rem 0;
      justify-content: space-around;
      
    }

    .goback-btn {
      background-color: unset;
      border: 1px solid #D3A937;
      color: #D3A937;
      padding: 0.65rem 3rem;
      border-radius: 4px;

      @media (max-width: 600px) {
        height: 40px;
      }
    }

    .gocancle-btn {
      background-color: #D3A937;
      color: #fff;
      padding: 0.65rem 3rem;
      border-radius: 4px;

      @media (max-width: 600px) {
        height: 40px;
      }
    }
   

  }

  

  .cancleBtn-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .cancleBtn {
      background-color: unset;
      border: 1px solid #e36176;
      color: #e36176;
      padding: 0.65rem 1.15rem;
      border-radius: 4px;
      @media (max-width: 600px) {
       font-size: 14px;
       padding: 0.15rem 0.55rem;
    }
    }
  }
}
.myContest {
  width: 31%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 1rem;
  @media (max-width: 600px) {
    width: 100%;
}

}
.cancleBtn-container {
  height: 70px;
  flex: 0.3;
}
.contests-cardLink-container {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  min-width: 100%;
  @media (max-width: 600px) {
    // justify-content: center;
    flex-direction: column;
}
}

.myContest__card {
  width: 100%;
  text-align: center;

  color: #2a2a2a7f;

  flex: 1;

  &:hover {
    color: var(--clr-primary-300);
  }

  .contestImg__holder {
    img {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  p {
    padding-top: 5px;
    padding-top: 1rem;
    // color: #2A2A2ABF;
    // font-size: 24px;
  }
 
}
.contest-title {

  @media (max-width: 600px) {
    font-size: 16px!important;
 }
}
.myContestCard__card {
  cursor: pointer;
}