
.passInput-container {
    width: 100%;
  

    .password-wrap {
        position: relative;
    }
   
    .text-input {
        width: 100%;
        background-color: #2A2A2A0C;
        padding: .9rem;
        border: none;
        margin: .5rem 0;
        font-size: .9rem;
        border-radius: .5rem;
        outline: none;
        
        
    }
    & > .label {
        text-align: left;
    }
    .eye-icon {
        background: none;
        outline: none;
        position:absolute;
        top: 22px;
        right: 20px;
    }
}
// *************** @Media screens ***************
@media (max-width: 1740px) {
        .label {
            font-size: 14px;
        }
  
    }

    .required-text {
        color:red
    }
    .error {
        color:red;
        font-size: 12px;
        font-style: italic;
    }