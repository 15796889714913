.upload-file .file-input-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 0.75rem;
    color: #212121;
    border: 1px dashed #ccc;
    border-radius: 0.5rem;
    height: 150px;
    justify-content: center;
  }
  .upload-headshot .photo-ctn .photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    /* border: 1px solid var(--dark); */
    overflow: hidden;
  }
  .upload-headshot .photo-ctn .photo img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100%;
  }
  .photo-ctn .headshot-btn-ctn {
    display: flex;
    justify-self: center;
  }
  .file-input {
    display: none;
  }