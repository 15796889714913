.logout-user--button__holder {
  display: flex;
  flex-direction: column;

  & button {
    display: flex;
    align-items: center;
    font-family: var(--lato-regular-font);
    text-transform: capitalize;
    color: #e36176;

    .logout-text {
      margin-left: 2rem;
    }

    & svg {
      font-size: 25px;
    }

  }
}

.confirmation-modal-container {
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  p:first-child {
    font-size: 22px;
    color: #2A2A2A;
    font-weight: bold;
  }
  p:nth-child(2) {
    color: #2A2A2A7F;
    font-size: 14px;
    padding: 2rem 0;
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

  .confirmation-cancle-btn {
    color: var(--clr-primary-300);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--lato-regular-font);
    text-transform: uppercase;
    padding: 0.2rem 1.15rem;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid var(--clr-primary-300);
    & span {
      font-size: calc(1rem - 3px);
      font-weight: 550;
      letter-spacing: 0.25px;
      padding: 0.5rem;
      text-align: center;

    }

    & svg {
      font-size: 1.25rem;
    }

    &.btn--small {
      width: 150px;
    }

    &.btn--large {
      min-width: 300px;
    }
  }

  }
}
