@use "./normalize";

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

*::selection {
  background-color: var(--clr-primary-300);
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html {
  font-size: 100%;
}
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
  font-family: var(--primary-font);
  box-sizing: border-box;
  background-color: var(--clr-light-100);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

//remove list style on li
ul li,
ol li {
  list-style-type: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
// @media (prefers-reduced-motion: reduce) {
//   html:focus-within {
//     scroll-behavior: auto;
//   }

//   *,
//   *::before,
//   *::after {
//     animation-duration: 0.01ms !important;
//     animation-iteration-count: 1 !important;
//     transition-duration: 0.01ms !important;
//     scroll-behavior: auto !important;
//   }
// }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-primary-300);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(223, 223, 223, 0.231);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-300);
  border-radius: 20px;
  border: 3px solid var(--clr-primary-300);
}
