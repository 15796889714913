.upload-headshot .photo-ctn {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 0.75rem;
    color: #d3a937;
    max-width: fit-content;
  }
  .upload-headshot .photo-ctn .photod {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    /* border: 1px solid var(--dark); */
    overflow: hidden;
  }
  .upload-headshot .photo-ctn .photo img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100%;
  }
  .photo-ctn .headshot-btn-ctn {
   text-wrap: nowrap;
  }
  .file-input {
    display: none;
  }