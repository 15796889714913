.wrapper1 {
    // width: 65%;
    // margin: 10px auto;
  
    @media (max-width: 950px) {
        width: 100%;
    }

    .row {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        .file-name {
            font-size: 0.75rem;
        }
      
    }

    @media (max-width: 950px) {
        width: 80%;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-right: 2.5rem;
    }
}



.content__holder {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
    
}
.about {
    background-color: #2A2A2A05;
    border-radius: 4px;
    padding: 0.5rem;
}
.heading {
    font-weight: 600;
    color: #2A2A2A;
    font-size: 1rem;
}
.dis__wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1rem;
    color: #2A2A2A7F;
}
.discription {
    font-size: 1rem;
    color: #2A2A2A7F;
}


.icon {
    font-size: 24px;
    color: #d3a937;
    cursor: pointer;
}

.change__pass {
    font-size: 16px;
    color: #d3a937;
    cursor: pointer;
}
.change__kyc {
    font-size: 16px;
    color: #d3a937;
    cursor: pointer;
}

.personal {
    margin-top: 20px;
    margin-bottom: 20px;
}

.rows {
    padding-right: 5rem;
    margin-bottom: 30px;
}

.rows1 {
    padding-right: 9.2rem;
}


.text {
    margin-right: 100px;
}

.details {
    display: flex;
    align-items: center;
}

.details1 {
    display: flex;
    align-items: center;
}

.title {
    font-size: 18px!important;
    font-weight: bold;
    padding-bottom: 1rem;
    color: #2A2A2A;
    @media (max-width: 950px) {
        font-size: 16px!important
    }
}
.info {
    font-size: 0.75rem;
    margin-bottom: 2rem;
}

.placeholder {
    font-size: 14px;
    font-weight: bold;
}

.icon__holder {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.profile__image {
    width: 70px;
    height: 70px;
    margin-right: 40px;
    img {
        border-radius: 100%;
        width: 70px;
        height: 70px;
        object-fit: cover;
    }
}

.profile__h3 {
    font-size: 18px;

}

.profile__p {
    font-size: 14px;

}

.title1 {
    font-size: 14px !important;
    color: #2A2A2A7F;

    @media (max-width: 600px) {
        // font-size: 14px !important;
        text-align: center;
    }
}

.placeholder {
    font-size: 14px;
    font-weight: bold;
}

.icon__holder {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.profile__image {
    width: 70px;
    margin-right: 40px;
}

.profile__h3 {
    font-size: 16px!important

}

.profile__p {
    font-size: 14px;

}

.my__profile__modal {
    padding: 1rem 1rem;
    // height: 100%;

    @media (max-width: 600px) {
        // padding: 4rem 2rem;
        // margin: 0px 1rem;
        width: 100%;
        overflow: hidden;
    
    }
}

.title1 {
    margin-bottom: 20px;
    color: black;
    font-size: 20px;
}

.first__input__holder {
    display: flex;
    justify-content: center;
    align-items: center;

 @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.first__input__hold {
    display: flex;
    justify-content: center;

}

.first__last__name1 {
    width: 48%;
    margin-right: 4%;
}

.first__last__name2 {
    width: 48%;
}

.drop {
    width: 48%;
    border-radius: 10px;
}

.gender {
    font-size: 1rem;
    margin-bottom: 8px;
}


.textarea-container {
    width: 100%;

    .label {
        margin-bottom: 2rem;
    }

    .textarea-input {
        width: 100%;
        background-color: #2A2A2A0C;
        padding: .8rem;
        border-radius: .5rem;
        border: none;
        margin: .5rem 0;

        &::placeholder {
            padding-top: .4rem;
        }
    }
}

.changes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    background-color: #d3a937;
    color: #ffff;
    width: fit-content;
    align-items: center;
    align-self: center;
    border-radius: 5px;
    & > button {
        color: white;
        padding: 0.5rem;
    
    }
}
.my__profile__modal111 {
    // padding: 1rem 1.5rem;
}

.eye__icon {
    position: relative;

    
}
.user-kyc {
   display: flex;
   gap: 1.5rem
}

.eye1 {
    position: absolute;
    bottom: 14.5rem;
    left: 32.5rem;
    cursor: pointer;

    @media (max-width: 600px) {
        bottom: 12.8rem;
        left: 21rem;
        font-size: 12px;
    }

    @media (max-width: 365px) {
        left: 20.5rem;
    }
}

.eye2 {
    position: absolute;
    bottom: 10.5rem;
    left: 32.5rem;
    cursor: pointer;
    @media (max-width: 600px) {
        bottom: 9.5rem;
        left: 21rem;
    }

    @media (max-width: 365px) {
        left: 20.5rem;
    }
}

@media (max-width: 600px) {
    .edit-prof-btn {
        width: fit-content;
        // margin-left: -2rem;
    }
}
