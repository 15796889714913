.generic--button {
  display: flex;

  .primary--button {
    background-color: var(--clr-primary-300);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: var(--lato-regular-font);
    text-transform: capitalize;
    padding: 0.1rem 1.15rem;
    border-radius: 4px;
    color: #fff;
    @media (max-width: 600px) {
      height: 30px;
    }
    & span {
      font-size: calc(1rem - 3px);
      font-weight: 550;
      letter-spacing: 0.25px;
      padding: 0.5rem;
      text-align: center;
      cursor: pointer;

      @media (max-width: 600px) {
        font-size: 10px;
      }
      
      &:last-child {
        padding-top: 0.7rem;
    }

  }

    & svg {
      font-size: 1.25rem;
    }

    &.btn--small {
      width: 150px;
    }

    &.btn--large {
      min-width: 300px;
    }
  }
  .voters-print {
    color: #341A1C;
  }

  .right__arrow {
    display: flex;
    align-items: center;

  }
  .right__arrow:hover {
    display: flex;
    align-items: center;
  }

}
.buttons {
  background-color: var(--clr-primary-300);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--lato-regular-font);
  text-transform: capitalize;
  // padding: 0.1rem 1.15rem;
  border-radius: 4px;
  color: #fff;
  @media (max-width: 600px) {
    height: 30px;
  }
  & span {
    font-size: calc(1rem - 3px);
    font-weight: 550;
    letter-spacing: 0.25px;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 10px;
    }
    
    &:last-child {
      padding-top: 0.7rem;
  }

}

  & svg {
    font-size: 1.25rem;
  }

  &.btn--small {
    width: 150px;
  }

  &.btn--large {
    min-width: 300px;
  }
}

.primary--button {
  background-color: var(--clr-primary-300);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--lato-regular-font);
  text-transform: capitalize;
  padding: 0.1rem 1.15rem;
  border-radius: 4px;
  color: #fff;
  @media (max-width: 600px) {
    height: 30px;
  }
  & span {
    font-size: calc(1rem - 3px);
    font-weight: 550;
    letter-spacing: 0.25px;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 10px;
    }
    
    &:last-child {
      padding-top: 0.7rem;
  }

}

  & svg {
    font-size: 1.25rem;
  }

  &.btn--small {
    width: 150px;
  }

  &.btn--large {
    min-width: 300px;
  }
}

// *************** @Media screens ***************
@media (max-width: 1000px) {

  
}

@media (max-width: 600px) {
  .primary--button  {
    padding: 0.1rem 1.5rem;
    border-radius: 2px;

  }
}
