@use "styles/abstracts/functions" as fn;

.avatar-dropdown {
  position: absolute;
  top: 58px;
  right: 0;
  transition: opacity 0.5s ease-in;
  z-index: 999;
  // z-index: fn.z("dropdown");

  &.visible {
    opacity: 1;
  }

  &.invisible {
    opacity: 0;
    clip: rect(0,0,0,0);
  }
}

.card {
  background-color: #fff;
  box-shadow: 0px 10px 20px #0000000a;
  border-radius: 10px;
  min-width: 300px;

  &__content {
    padding: 1.5rem;
  }
}

.view-avatar__details {
  display: flex;
  flex-direction: column;
}

.view-details__link {
  height: 100%;
  display: flex;
  align-items: center;
}

.avatar__data {
  display: flex;
  flex-direction: column;

  & span {
    padding: 0.25rem 1rem;
  }
}
.avatar__data-name {
  color: var(--text-primary-color);
  font-family: var(--lato-regular-font);
}

.avatar__data-email {
  color: var(--clr-dark-300);
  font-family: var(--lato-light-font);
}

.logout-user__cta {
  margin-top: 3rem;
  // background-color: red;
  // color: #fff
}
.logout-modal {
  width: 90%;
  margin: 0 auto;
  text-align: center;

  p:first-child {
    columns: #2A2A2A;
    font-size: 22px;
  }
  p:nth-child(2){
    color: #2A2A2A7F;
    font-size: 14px;
    padding: 2rem 0;
  }
  
  .btns-container {
    display: flex;
    justify-content: space-around;
    padding-bottom: 2rem;
  }
}