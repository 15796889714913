.notification-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.15rem 0.5rem;

  & .button-text {
    padding: 0.15rem 0 0.15rem 1rem;
    font-family: var(--lato-light-font);
    font-weight: 400;
    @media (max-width: 600px) {
      // display: none;
    }
  }

  & svg {
    color: var(--clr-dark-200);
  }
}

.notification--indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  top: 5px;

  @media (max-width: 600px) {
    top: -2px;
    left: -5px;
  }

  & svg {
    color: var(--clr-primary-300);
  }
}

// ******* Media screens **********
@media (max-width: 600px) {
  .notification-button {
    width: fit-content;
    padding: 0;
    font-size: 26px;
    // background-color: unset;
    .bell {
      color: #808080;
    }

  }

  .notification--indicator {
    .dot {
      width: 18px;
      height: 18px;
    }
  }
}