@use "styles/abstracts/functions" as *;

.card--content {
    background-color: #fff;
    padding: 0;
    font-family: var(--lato-regular-font);

    @media (max-width: 950px) {
        width: 100%;
    }
}


.card--container {
    padding: rem(30);
}

// *************** @Media screens ***************
@media (max-width: 600px) {
    .card--content {
        width: 100%;
    }
    .card--container {
        padding: 1rem;
    }
}