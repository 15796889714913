// **********modal styling**************

.bg-img {
  position: absolute;
  width: 35%;
  bottom: 24rem;
  left: 33rem;
  img {
    width: fit-content;
  }

  @media (max-width: 950px) {
    width: 20%;
  }
}

.video-modal {
  // width: 90%;
  // margin: 0 auto;
  padding-bottom: 2rem;

  .avatar-name {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    // padding-left: 5rem;

    .avatar-container {
      width: fit-content;
      img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
      }
    }
    .name-carrier {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
    }
  }

  .cover {
    // padding-left: 5rem;
    font-size: 16px;
    color: #2a2a2abf;
  }

  .jone__doe {
    color: #2a2a2a;
  }

  .musical {
    color: #2a2a2a7f;
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .btn-container {
    background-color: var(--clr-primary-300);
    text-align: center;
    display: flex;
    align-items: center;
    width: 8rem;
    margin: auto;
    padding: 0.4rem 1.15rem;
    font-family: var(--lato-regular-font);
    text-transform: uppercase;
    border-radius: 4px;
    color: #fff;
  }
}

.confirmation-modal {
  text-align: center;

  .confirm-btn {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0;
  }
}

.card__contestant__view {
  // max-width: 32%;
  flex: 1;
  cursor: pointer;
  background-color: #fbf7eb;
  margin-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.card__contestant__view:hover {
 
  background-color: #fff;
}

.footer-description {
  color: #e36176;
  font-size: 12px;
  width: 100%;
  text-align: justify;

  @media (max-width: 950px) {
    padding-left: 4rem;
  }

  @media (max-width: 600px) {
    padding: 0 1.5rem;
  }
}

.card__wrapper {
  font-size: 12px;

  .title {
  //  margin-top: 1rem;
    padding: 0px 10px;
  }
  .title1 {
    margin: 10px 0;
    padding: 0px 10px;
    background-color: gray;
    opacity: 0.5;
  }
}
.video__discription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .paragraph {
    font-size: 12px;
    margin: 6px 0;
    padding: 0px 10px;
  }
}

.voter__contestant__bg {
  background-color: #ffffff;
  padding: 2.5rem;

  @media (max-width: 950px) {
    padding: 0 !important;
    width: 100% !important;
  }
}
.voter-contestants {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  gap: 1rem;
  width: 100%;
  @media (max-width: 450px) {
   flex-direction: column;
  }
}

.main__wrap {


  @media (max-width: 950px) {
    width: 100% !important;
    padding: 0 !important;
  }
}

.voted-note {
font-size: 12px;
color: #d3a937;
font-style: italic;
}
.contestImg__holder {
  img {
    width: 310px;
    height: 170px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.voter-contestant {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 30%;
  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 414px) {
    width: 100%;
  }
  .contestant-video {
    width: 100%;
    min-width: 100%;
    height: 180px;
    // display: none;
  }
  .contestant-video-empty {
    width: 100%;
    min-width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    gap: 0.5rem;
    opacity: 0.2;
    cursor: not-allowed;
    // display: none;
  }
  // overflow: hidden;
  @media (max-width: 450px) {
    max-width: 100%;
    width: 100%;
  }

  // @media (max-width: 950px) {
  //     flex-wrap: wrap !important;
  //     justify-content: center !important;
  //     gap: 1rem !important;

  // }

  .voted__for {
    opacity: 0.5;
  }
}

.second__rol {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 1rem !important;
  }

  // .items {
  //     width: calc(100% / 4);
  // }
}

.headset-icon {
  width: 70%;
  img {
    width: 200px;
    margin-left: -8rem;
    margin-top: -2rem;

    @media (max-width: 950px) {
      width: 100px;
      margin-left: -2rem;
      margin-top: 0;
    }
  }
}

// *************** @Media screens ***************
@media (max-width: 1740px) {
  .bg-img {
    width: 40% !important;
    bottom: 15rem;
    left: 27rem;
  }

  .first__rol {
    flex-wrap: nowrap;
    gap: 1rem;
  }

  .second__rol {
    flex-wrap: nowrap;
    gap: 1rem;
  }
}
