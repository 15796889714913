.round-user-avatar {
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100%;

  & img {
    width: inherit;
    height: 100%;
    object-fit: cover;
  }
}

// *********** @Media screens *********

@media (max-width: 950px) {
  .round-user-avatar {
    width: 40px;
    height: 40px;
  }
}
