
.textInput-container {
    width: 100%;

    .label {
        margin-bottom: 1rem;
    }

    .text-input {
        width: 100%;
        background-color: #2A2A2A0C;
        padding: .8rem;
        border: none;
        // margin: .5rem 0;
        font-size: .9rem;
        border-radius: .5rem;

        @media (max-width: 600px) {
            padding: .5rem;
            border-radius: .2rem;
            font-size: 12px;
        }

        & input {
            border: 1px solid transparent;
            background-color: var(--clr-dark-100);
            outline: none;
            & :focus {
                outline: none !important;
            }
        }
    
        &::placeholder {
            padding-top: .4rem;
        }
    }
}
.required-text {
    color:red
}

// **************@Media Screen *******************
// @media (max-width: 600px) {
    
// }