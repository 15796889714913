.avatar-button {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  // min-width: 120px;
}

.avatar-button__toggle {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0.5rem;
}

.avatar-button__icon {
  display: flex;
  flex-direction: column;
  width: 25px;
  transition: transform 0.5s ease-in;

  //Javascript Class
  &.icon--rotated {
    transform: rotate(180deg);
  }
  &.icon--unset-rotate {
    transform: rotate(0);
  }
  & svg {
    height: 100%;
    width: 100%;
  }
}



