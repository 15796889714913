
@use "../abstracts/mixins" as mx;

@include mx.font-face("califb", "../../assets/fonts/califb", 500, normal, ttf);
@include mx.font-face(
  "Lato-Regular",
  "../../assets/fonts/Lato-Regular",
  normal,
  ttf
);
@include mx.font-face(
  "Lato-Light",
  "../../assets/fonts/Lato-Light",
  normal,
  ttf
);
@include mx.font-face("Lato-Bold", "../../assets/fonts/Lato-Bold", normal, ttf);
