.contests-cardLink-container {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    // justify-content: center;
    flex-direction: column;
}
}
.contest-title {
  text-align: center;
}